import React from "react"
import {Link} from "gatsby";
import HandSanitizerImage from "../images/hand-sanitizer";
import DisinfectantImage from "../images/disinfectant";
import DetergentImage from "../images/detergent";
import FourthImage from "../images/fourth";

const ProductThumbs = () => (
  <div className="product-thumbs">
    <div className="product-thumb">
      <Link to="/products/hand-sanitizer"><HandSanitizerImage /></Link>
      <h3>
        <Link to="/products/hand-sanitizer">
          8-HOUR<br />
          Hand Sanitizer
        </Link>
      </h3>
      <p><Link className="button bordered" to="/products/hand-sanitizer">Order</Link></p>
    </div>
    <div className="product-thumb">
      <Link to="/products/disinfectant"><DisinfectantImage /></Link>
      <h3>
        <Link to="/products/disinfectant">
          Disinfectant providing several<br />
          weeks of protection
        </Link>
      </h3>
      <p><Link className="button bordered" to="/products/disinfectant">Order</Link></p>
    </div>
    <div className="product-thumb">
      <Link to="/products/detergent"><DetergentImage /></Link>
      <h3>
        <Link to="/products/detergent">
          Laundry detergent safeguards your<br />
          laundry after multiple washes
        </Link>
      </h3>
      <p><Link className="button bordered" to="/products/detergent">Order</Link></p>
    </div>
    <div className="product-thumb">
      <Link to="/products/fourth"><FourthImage /></Link>
      <h3>
        <Link to="/products/fourth">
          Product<br />
          Number Four
        </Link>
      </h3>
      <p><Link className="button bordered" to="/products/fourth">Order</Link></p>
    </div>
  </div>
)

export default ProductThumbs
